<!--
 * @Description: 商圈、景点等
 * @Author: 琢磨先生
 * @Date: 2022-05-09 10:59:09
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-02 10:10:14
-->


<template>
  <el-card class="box">
    <el-button
      type="primary"
      size="small"
      @click="onEdit('0')"
      round
      icon="plus"
      >新增</el-button
    >
    <el-button
      type="success"
      size="small"
      @click="onEdit('1')"
      round
      icon="plus"
      >新增分类</el-button
    >

    <el-button
      size="small"
      @click="onCreateIndex"
      round
      icon="Refresh"
      :loading="saving"
      >创建索引</el-button
    >
    <el-button size="small" @click="visible = true" round :loading="saving"
      >测试检索</el-button
    >
  </el-card>

  <el-card class="box data bnb_location">
    <el-tabs v-model="activeName">
      <el-tab-pane label="商圈、景点" name="0">
        <el-row :gutter="20">
          <el-col :span="4">
            <el-card shadow="never">
              <el-tree
                ref="tree"
                :data="types"
                node-key="id"
                :expand-on-click-node="false"
                highlight-current
                :props="{ children: 'children', label: 'name' }"
                default-expand-all
                @current-change="treeNodeChange"
              />
            </el-card>
          </el-col>
          <el-col :span="20">
            <el-form-item label>
              <el-select
                v-model="query.areaId"
                placeholder="切换城市区域"
                @change="areaChange"
              >
                <el-option
                  v-for="item in areas"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-table :data="tableData" v-loading="loading" border>
              <el-table-column prop="id" label="编号" width="100" />
              <el-table-column prop="name" label="名称" width="200" />
              <el-table-column label="状态" width="180">
                <template #default="scope">
                  <el-tag type="danger" v-if="scope.row.is_stop">停用</el-tag>
                  <el-tag type="success" v-else>正常</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="位置经纬" width="200">
                <template #default="scope">
                  {{ scope.row.lng }},{{ scope.row.lat }}
                  <!-- <el-button
                    size="small"
                    type="text"
                    @click="openMap(scope.row)"
                  >
                    <el-icon><add-location /></el-icon>定位</el-button
                  >-->
                </template>
              </el-table-column>

              <el-table-column prop="create_at" label="创建时间" sortable />
              <el-table-column label="操作" width="140">
                <template #default="scope">
                  <el-button
                    type="primary"
                    link
                    size="small"
                    @click="onEdit('0', scope.row)"
                    >修改</el-button
                  >
                  <el-popconfirm
                    title="去定要删除？"
                    @confirm="onDelete('0', scope.row)"
                  >
                    <template #reference>
                      <el-button type="danger" link size="small"
                        >删除</el-button
                      >
                    </template>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="分类" :disabled="!loadedType" name="1">
        <el-table :data="types" row-key="id" default-expand-all border>
          <el-table-column prop="name" label="名称" />
          <el-table-column label="状态" width="180">
            <template #default="scope">
              <el-tag type="danger" v-if="scope.row.is_stop">停用</el-tag>
              <el-tag type="success" v-else>正常</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="sequence" label="序号" width="180" />
          <el-table-column
            prop="create_at"
            label="创建时间"
            sortable
            width="180"
          />
          <el-table-column label="操作" width="140">
            <template #default="scope">
              <el-button
                type="primary"
                link
                size="small"
                @click="onEdit('1', scope.row)"
                >修改</el-button
              >
              <el-popconfirm
                title="去定要删除？"
                @confirm="onDelete('1', scope.row)"
              >
                <template #reference>
                  <el-button type="danger" link size="small">删除</el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </el-card>
  <edit-type :item="currentType" :types="types" :reload="loadTypes"></edit-type>
  <edit-location
    :item="current"
    :types="types"
    :areas="areas"
    :reload="loadData"
  ></edit-location>

  <el-dialog title="测试ES检索结果" v-model="visible" width="600px">
    <el-table :data="indexData" border height="400" v-loading="indexLoading">
      <el-table-column label="名字" prop="name">
        <template #header>
          <el-input
            v-model="keyword"
            placeholder="名称检索"
            @input="keywordChange"
          ></el-input>
        </template>
        <!-- <template #default="scope">{{scope.row.name}}</template> -->
      </el-table-column>
    </el-table>
  </el-dialog>
</template>
<script>
import EditLocation from "./edit_location.vue";
import EditType from "./edit_location_type.vue";

export default {
  components: {
    EditLocation,
    EditType,
  },
  data() {
    return {
      loading: false,
      saving: false,
      visible: false,
      indexLoading: false,
      activeName: "0",
      current: null,
      currentType: null,
      //地区选择时传递的城市区域对象
      area: null,
      query: {
        areaId: "",
        typeId: "",
      },
      //类型数据是否已经加载完成
      loadedType: false,
      types: [],
      areas: [],
      tableData: [],
      keyword: "",
      //索引结果
      indexData: [],
    };
  },
  created() {
    this.loadArea();
    this.loadTypes();
  },

  methods: {
    /**
     * 获取可搜索的城市区域
     */
    loadArea() {
      this.$http.get("admin/v1/common/search_area").then((res) => {
        if (res.code == 0) {
          this.areas = res.data;
          if (res.data.length > 0) {
            this.query.areaId = res.data[0].id;
            this.loadData();
          }
        }
      });
    },
    /**
     * 获取类型列表
     */
    loadTypes() {
      this.$http.get("admin/v1/location/types").then((res) => {
        this.loadedType = true;
        if (res.code == 0) {
          this.types = res.data;
          if (res.data.length > 0) {
            this.query.typeId = res.data[0].id;
            //tree默认选中第一个
            this.$nextTick(() => {
              this.$refs.tree.setCurrentKey(res.data[0].id);
            });
            this.loadData();
          }
        }
      });
    },
    /**
     * 加载数据
     */
    loadData() {
      if (!(this.query.areaId && this.query.typeId)) {
        return;
      }
      this.loading = true;
      this.current = null;
      this.$http
        .get(
          `admin/v1/location?areaId=${this.query.areaId}&typeId=${this.query.typeId}`
        )
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * 新增、修改
     */
    onEdit(type, item) {
      if (type == "0") {
        this.current = item ? item : {};
      } else {
        this.currentType = item ? item : {};
      }
    },
    /**
     * tree节点点击事件
     */
    treeNodeChange(item) {
      this.query.typeId = item.id;
      this.loadData();
    },
    /**
     * 城市选择变化
     */
    areaChange(val) {
      this.query.areaId = val;
      this.loadData();
    },

    /**
     * 删除
     */
    onDelete(type, item) {
      this.$http
        .get(
          `admin/v1/location/${type == "0" ? "del" : "type/del"}?id=` + item.id
        )
        .then((res) => {
          if (res.code == 0) {
            if (type == "0") {
              this.loadData();
            } else {
              this.loadedType();
            }
          }
        });
    },

    /**
     * 初始化创建索引
     */
    onCreateIndex() {
      this.$confirm("是否要初始化创建ES索引", "提示").then(() => {
        this.saving = true;
        this.$http
          .get("admin/v1/location/createIndex")
          .then((res) => {
            if (res.code == 0) {
              this.$message.success(res.msg);
            }
          })
          .finally(() => {
            this.saving = false;
          });
      });
    },

    /**
     * 检索文字更改
     */
    keywordChange() {
      this.indexLoading = true;
      this.$http
        .get("admin/v1/location/testSearch?keyword=" + this.keyword)
        .then((res) => {
          if (res.code == 0) {
            this.indexData = res.data;
          }
        })
        .finally(() => {
          this.indexLoading = false;
        });
    },
  },
};
</script>

<style>
.bnb_location .el-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.bnb_location .el-tabs .el-tabs__content {
  height: 1px;
  flex-grow: 1;
}
.bnb_location .el-tabs .el-tabs__content .el-tab-pane,
.bnb_location .el-tabs .el-tabs__content .el-tab-pane .el-row {
  height: 100%;
}
.bnb_location .el-tabs .el-tabs__content .el-tab-pane{
  display: flex;
  flex-direction: column;
}
.bnb_location .el-tabs .el-tabs__content .el-tab-pane .el-col {
  display: flex;
  flex-direction: column;
}
</style>